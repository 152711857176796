@import '_vars';

*{
    margin: 0;
    padding: 0;
    font-family: Comfortaa, Sans-serif;
    box-sizing: border-box;
}

a {
    color: $darkred;
    text-decoration: none;
}

h3{
	color: $white;
	margin-bottom: 5px;
}

.center{
	text-align: center !important;
}

.body__container{
	background: url('bg1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
    max-width: 100vw;
	min-height: 100vh;
	overflow: auto;
}

.visible{
   display: block !important; 
}

.loading-overlay{
    position: fixed;
    width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.75);
	z-index: 9;
	display: none;
}

.wrapper{
	display: flex;
	flex-wrap: wrap;
	padding:30px;
	min-height: calc(100% - 86px);
	max-width: 1400px;
	margin: 0 auto;
	align-items: flex-start;
}

.content__section{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.content{
	margin-left: 30px;
	width: calc(100% - 490px);
}

.content__wrapper{
	background: $op2;
	margin-bottom: 30px;
	padding: 15px;
	color: $white;
	text-align: center;
	min-width: 100%;
	border: $border;
	border-radius: 5px;
}

.button {
	text-decoration: none;
	outline: none;
	display: inline-block;
	padding: 10px 30px;
	margin: 10px 0px;
	position: relative;
	overflow: hidden;
	border: 2px solid $darkred;
	border-radius: 8px;
	font-family: 'Comfortaa', sans-serif;
	font-weight: bold;
	color: $darkred;
	transition: .2s ease-in-out;
	background: $white;
  }
  .button:before {
	content: "";
	background: linear-gradient(90deg, rgba(255,255,255,.1), rgba(255,255,255,.5));
	height: 70px;
	width: 70px;
	position: absolute;
	top: 0px;
	left: -105px;
	transform: skewX(-45deg);
  }
  .button:hover {
	background: $darkred;
	color: #fff;
	cursor: pointer;
  }
  .button:hover:before {
	left: 350px;
	transition: .5s ease-in-out;
  }

  .added_to_cart{
	background-color: #e28e29;
	color: #fff;
}

.alert{
	background: linear-gradient(135deg, $darkred 0%, $lightred 100%);
	text-align: center;
	padding: 10px 5px;
	position: relative;
	}

.closealert::after{
	content: '';
	width: 15px;
	height: 3px;
	background: #fff;
	transform: rotate(-45deg);
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
}

.closealert::before{
	content: '';
	width: 15px;
	height: 3px;
	background: #fff;
	transform: rotate(45deg);
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
}
	
.alert a{
	color: #ffd900;
}

.loader{
	width: 100%;
	height: 100%;
	background: (rgba($color: #000000, $alpha: 0.7));
	position: fixed;
	display: none;
	z-index: 1000;
	top: 0;
	left: 0;
}

  @media screen and (max-width: 1025px) {
	.side-menu, .content{
		width: 90%;
		margin: 0 auto;
	}

	.content{
		margin-top: 30px;
		box-sizing: border-box;
	}

	.content__section{
		justify-content: space-between;
	}

}

@media screen and (max-width: 980px) {
	.content__section{
		justify-content: center;
		width: 100%;

		&>a:last-child {
			margin-bottom: 15px;
		}
	}

	.g-item__img{
		width: 100%;
	}
}

@media screen and (max-width: 780px) {
	.header{
		display: none;
	}
	.cart{
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: unset;

		&__cont__input{
			max-width: 160px;
		}

		&__table{
			border-collapse: collapse;
			text-align: center;
			margin: auto;
	
			th, td:first-child {
				padding: 3px;
			}
		}
	}
}

@media screen and (max-width: 570px) {
	.header{
		display: none;
	}

	.cart__cont__input{
		max-width: 60px;
		text-align: center;
	}
	.wrapper{
		padding: 0px;
	}
	.content__wrapper{
		padding: 5px;
	}

	.side-menu, .content {
		width: 100vw;
		margin: 0px auto;
		padding: 0px;
		min-width: unset;
	}

	.content{
		margin-top: 20px;
		@-moz-document url-prefix() {
			padding-bottom: 70px;
			}
	}
}
