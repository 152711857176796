.g-item{
	margin-bottom: 30px;
    padding: 6px;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, .8);
    position: relative;
	text-shadow:2px 2px 2px #FFF;
	border-radius: 5px;

    &__img{
    	width: 400px;
    	height: auto;
    }

    &__description{
    	position: absolute;
    	right: 15px;
    	bottom: 17px;
    	text-align: right;
    	color: white;
    	text-shadow: 1px 1px 2px black, 0 0 1em black;
    	font-family: "Open Sans", "Arial", sans-serif;
    	font-weight: 600;
    }

    &__name, &__price{
    	font-family: inherit;
    }
}

.cursor_not_allowed:hover{
	cursor: not-allowed !important;
}
