@import '../../app/_vars';


.selected{
    display: flex;
    justify-content: center;
    align-items: center;
}


.item_content__wrapper{
    background: $op2;
	margin-bottom: 30px;
	padding: 15px;
	color: $white;
	text-align: center;
	width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border:  $border;
    border-radius: 5px;
}

.item_wrapper{
    max-width: 700px;
    min-height: 500px;
}

.youtubeVideo{
    min-height: 500px;
}

.sliderImg{
    width: 100% !important;
}


.nav_arrow{
    position: relative;
    font-size: 28px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .6);

    i{
        position: relative;
        top: 50%;
        left:50%;
        transform: translate(-100%, -50%);
    }

    &:hover{
        background-color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
    }
}

.description{
    padding: 15px 10px;
    background-color: $darkred;
    color:rgba(255,255,255, 1);
}


.item_price{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px
}

.prices_row{
    display: flex;
    align-items: center;
}

.swiper-button-prev, .swiper-button-next {
    color: $darkred !important;
}

.swiper-pagination-bullet{
    background: $white !important;
    opacity: 0.8 !important;
    border: $darkred 1px solid !important;
}

.swiper-pagination-bullet-active{
    background: $darkred !important;
}


@media (max-width: 1400px){
    .nav_arrow{
        display: none;
    }
    .item_wrapper{
        max-width: 100%;
    }
    .item_price{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;

        & h3 {
            width: 100%;
        }
    }
}

@media (max-width: 1000px){
    .item_content__wrapper{
        max-width:100%;
        box-sizing: border-box;
    }
    .item_price h3{
        width: 100%;
    }
}

@media (max-width: 700px){
    .swiper-container{
        width: 100%;
    }
    .sliderImg, .youtubeVideo{
        max-width: 290px;
        min-height: unset;
    }
    .item_wrapper{
        min-height: unset;
    }
}