.disclaimer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #FF755C 0%, #FF9A35 100%);
    font-size: 20px;
    letter-spacing: 0.1em;
    line-height: 150%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    & .accept:hover {
        border-color: white;
    }

    & .text {
        flex-grow: 1;
    }
}

@media (max-width: 768px){
    .disclaimer {
        padding: 10px;
        line-height: 100%;
        letter-spacing: normal;

        & .text {
            overflow-y: scroll;
        }

        & .accept {
            height: 50px;
        }
        
        & h3 {
            padding: 15px;
        }
    }
}