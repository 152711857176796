@import '../app/vars';

.header{
	border-bottom: 7px solid rgba(255,255,255, 0.1);
}

.topmenu-right{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.lang{
	list-style: none;
	display: flex;

	& a{
		color: #fff;
	}
}

.header__navmenu{
	background-color: $darkred;
	padding: 0px 20px;
	display: flex;
	justify-content: space-between;
}

.navmenu__ul{
	display: flex;
	list-style: none;
	color: #fff;
	font: 18px cursive;
	line-height: 20px;
	align-items: center;
}

.navmenu__item{
	display: block;

	& i {
		font-size: 22px;
	}	
}

.navmenu__item:hover{
	color: rgba(0, 0, 0, 0.7);
	cursor: pointer;
	transition: 0.7s;
	font-weight: 700;
}

.navmenu__link{
	color: inherit;
	padding: 10px 20px;
}

.open_cart{
	width: 30px;
	height: 30px;

	color: #fff;
	font-size: 18px;
	margin-top: 17px;

	cursor: pointer;

	&:hover{
		color: #e28e29;
		&::before{
			content: 'X';
			background-color: #fff;
			border: 1px solid $darkred;
			border-radius: 5px;
			padding: 5px;
			margin-top: 2px;
			transition: 0.1s;
		}
	}
}
