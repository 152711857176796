@import '../app/vars';

.footer{
	border-top: 7px solid rgba(255,255,255, 0.2);
	position: fixed;
    bottom: 0px;
	width: 100%;
	z-index: 300;



	&__container{
		background-color: $darkred;
		padding: 7px 0px;
		width: 100%;
		position: relative;
		color:#FFF;
		text-align: center;
	}
}
