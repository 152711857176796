@import '../app/vars';

.side-menu{
	min-width: 450px;
	max-width: 500px;
	border: $border;
	border-radius: 5px;


	&__logo{
	background-color: $op2;
    text-align: center;
    padding: 30px;
	}

}

.logo{
	&__companyname{
	font-weight: 700;
	color: $darkred;
	}

	&__hint{
		margin-top:2px;
		font-size: 0.8em;
		color: $white;
	}
}

.side-menu__item{
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: $white;
    border-top: 1px solid #c7cdd8;
    background: $op2;

	&:hover{
		background: rgba(30,30,30,0.9);
		color: #fff;
		transition: 0.7s;
		cursor: pointer;
	}
}

.side-menu__link{
	font-size: 18px;
	display: block;
	text-align: center;
	color: inherit;
	padding: 18px 0px;
	font-family: Open Sans, Arial, sans-serif;
}

.side-menu__toggle-menu{
	display: block;
    padding: 10px 0px;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    background-color: $darkred;
}