.cart{
	background-color: #fff;
    left: 50%;
    top: 50%;
	transform: translate(-50%,-50%);
	padding: 10px;
	box-shadow: 1px 1px 2px black, 0 0 1em black;
	overflow: auto;
	z-index: 10;
	position: fixed;
	max-height: 90%;
	display: none;
	flex-direction: column;
	
	&__input{
		outline: none;
		display: inline-block;
		border-radius: 2px;
		border: 1px solid #ccc;
		padding: 10px;
		box-sizing: border-box;
		width: 100%;
	}

	& p{
		padding: 5px;
	}

	&__input_form{
		display: flex;
		flex-direction: column;
	}


	&__table{
		border-collapse: collapse;
		text-align: center;
		margin: auto;
		width: 100%;

		th, td:first-child {
			background: #F3C997;
			margin: auto;
			color: white;
			padding: 10px 20px;
			text-shadow: 1px 1px 2px black;
		}
		th, td {
			border-style: solid;
			border-width: 0 1px 1px 0;
			border-color: white;
			
		}
		td {
		background: #FFEDD8;
		}
		th:first-child, td:first-child {
		text-align: left;
		}
		& .cart_header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__close {
		font-size: 20px;
		color: white;
		cursor: pointer;
	}

	&__footer{
		display: flex;
		justify-content: space-between;
	}

	&__cont__input{
		border: none;
		background: transparent;
		text-align: right;
		width: 35px;
		font-family: Comfortaa, sans-serif;
		font-size: 16px;
}

}

#flow_cart {
	width: 5em;
	height: 5em;
	left: 10px;
	bottom: 60px;
    z-index: 100;
    position: fixed;
    text-shadow: 1px 1px 2px black, 0 0 1em black;
    opacity: 0.8;
    border: #777777 3px solid;
    cursor: pointer;
    display: none;
}

.success{
	width: 100vw;
	height: 100vh;
	background: rgba($color: #000000, $alpha: 0.8);
	position: fixed;
	z-index: 9999;
	display: none;
	justify-content: center;
	align-items: center;

	.success__text{
		text-align: center;
		min-width: 300px;
		padding: 50px 30px;
		display: block;
		background: linear-gradient(135deg, #FF755C 0%, #FF9A35 100%);
		box-shadow: 0px 19px 4px -9px rgba(25, 22, 47, 0.497832);
		border-radius: 10px;
		font-size: 20px;
		letter-spacing: 0.1em;
		line-height: 150%;
	}
}


.wrongPhone{
	outline: 2px red solid;
	outline-offset: -3px;
	border-radius: 5px;
}

.rightPhone{
	outline: 2px green solid;
	outline-offset: -3px;
}

//city.css
.react-dadata__container{
	border-radius: 2px;	
	box-sizing: border-box;
}

.react-dadata__suggestions{
	max-width: 300px;
	overflow: hidden;
	box-sizing: border-box;
}

@media (max-width: 600px) {
	#flow_cart{
		width: 3.5em;
		height: 3.5em;
		left: 5px;
		bottom: 38px;
	}
}